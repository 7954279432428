/**
 * callback of the IntersectionObserver
 * This is what happens when the individual entry is scrolled into view
 * to add an animated svg icon you simple need to wrap in in an html tag with the class .animate-icon
 * the svg needs to contain the actual animation in a style tag (CSS Animation in Keyshape)
 * to avoid doublicated IDs the Object IDs in the svg should be prefixed (export in Keyshape)
 * write motion parts as transforms needs to be selected (export in Keyshape)
 */ 
 let options = {
    rootMargin: '0px',
    threshold: 0.6
}

const callback = (entries) => {

    entries.forEach(entry => {
        
        console.log(entry, entry.root);
        if (entry.isIntersecting) {

            let $elemParent = $(entry.target);
            let $elem = $(entry.target).children();

            if ($elem.hasClass('animate-icon-show')) {
                return
            }

            $elem.clone().appendTo($elemParent).addClass('animate-icon-show');
            $elem.remove();
        }
    });
}

/**
 * 1]Create a new intersectionObserver object,
 * which will accept a callback function and options as parameters.
 */
let observer = new IntersectionObserver(callback, options);

/**
 *  2]Select all elements that have ".animate-icon" 
 * makes it possible to have multiple svg icons with the same behavior
 */
const animationItems = document.querySelectorAll('.animate-icon');

/**
 *  3]Loop through selected elements and add to the observer watch list.
 */
animationItems.forEach(item => {
    observer.observe(item)
}) 